<template>
  <div>SCHLUMPF</div>
</template>

<script lang="ts" setup>
defineBlokkliFragment({
  name: 'Test',
  label: 'test',
})
</script>
