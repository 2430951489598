<template>
  <div class="container">
    <figure
      v-if="structuredTable || isEditing"
      @keydown.enter.capture="onClick"
    >
      <figcaption
        v-if="structuredTable?.caption"
        class="font-bold text-lg lg:text-2xl mb-10 lg:mb-32"
        v-html="structuredTable.caption"
      />
      <StructuredTable
        v-if="structuredTable"
        v-bind="structuredTable"
        :id="uuid"
        :mobile-style="options.mobileTableStyle"
        :sticky="options.sticky"
        :equalize-widths="options.equalizeWidths"
      />
    </figure>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphTableFragment } from '#graphql-operations'

const { onClick } = useClickTriggerProxy()

const { options, uuid, isEditing } = defineBlokkli({
  bundle: 'table',
  options: {
    equalizeWidths: {
      type: 'checkbox',
      label: 'Alle Spalten gleich breit',
      default: true,
    },
    mobileTableStyle: {
      type: 'radios',
      label: 'Mobile Darstellung',
      default: 'horizontal',
      displayAs: 'icons',
      options: {
        vertical: {
          label: 'Vertikal',
          icon: 'icon-blokkli-option-table-vertical',
        },
        horizontal: {
          label: 'Horizontal',
          icon: 'icon-blokkli-option-table-horizontal',
        },
      },
    },
    sticky: {
      type: 'radios',
      label: 'Sticky',
      default: 'left',
      options: {
        none: '-',
        left: 'Links',
        top: 'Oben',
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('figcaption')?.innerText,
    determineVisibleOptions: (ctx) => {
      if (ctx.options.mobileTableStyle === 'vertical') {
        return ['equalizeWidths', 'mobileTableStyle']
      }

      return ['equalizeWidths', 'mobileTableStyle', 'sticky']
    },
  },
})

defineProps<{
  structuredTable: ParagraphTableFragment['structuredTable']
}>()
</script>
