<template>
  <div class="container mx-auto py-8">
    <h2
      v-blokkli-editable:field_title
      class="text-3xl md:text-4xl mb-10 lg:mb-14"
    >
      {{ title }}
    </h2>
    <div class="relative border-l-2 border-gray-300">
      <div
        v-for="(milestone, index) in milestones"
        :key="index"
        class="mb-8 pl-8 last:mb-0 flex items-start"
      >
        <div
          class="absolute size-4 bg-white border-2 border-gray-300 rounded-full -left-2"
        />
        <div class="ml-4">
          <h2
            :class="index % 2 === 0 ? 'text-plum-800' : 'text-gray-500'"
            class="text-4xl font-bold"
          >
            {{ milestone.year }}
          </h2>
          <p
            class="mt-2 text-gray-700 leading-relaxed"
            v-html="milestone.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphMilestonesFragment } from '#graphql-operations'

defineProps<{
  title?: string
  milestones?: ParagraphMilestonesFragment['milestones']
}>()

defineBlokkli({
  bundle: 'milestones',
})
</script>
