<template>
  <div class="container mx-auto py-8">
    <h2
      v-blokkli-editable:field_title
      class="text-3xl md:text-4xl mb-10 lg:mb-14"
    >
      {{ title }}
    </h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      <div
        v-for="(fact, index) in factFigures"
        :key="index"
        class="text-center p-4 border rounded-lg"
      >
        <div class="text-2xl font-bold">{{ fact.number }}</div>
        <div class="mt-2">{{ fact.text }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphInfoBoxFragment } from '#graphql-operations'

defineProps<{
  title?: string
  factFigures?: ParagraphInfoBoxFragment['factFigures']
}>()

defineBlokkli({
  bundle: 'infobox',
})
</script>
