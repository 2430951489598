<template>
  <div
    v-if="videoUrl"
    :class="[
      options.spacing === 'small' ? 'py-6 lg:py-10' : '',
      options.spacing === 'large' ? 'py-12 lg:py-20' : '',
      gridContainerSize,
      paragraphClassList,
    ]"
  >
    <div :class="[layoutClasses]">
      <VuepalRemoteVideo v-slot="{ embedUrl, thumbnailUrl }" :url="videoUrl">
        <figure class="w-full max-w-[1920px]">
          <iframe
            v-if="embedUrl && isPlaying"
            :src="embedUrl"
            allow="autoplay; encrypted-media; picture-in-picture"
            allowfullscreen
            class="aspect-video w-full lg:px-0"
          />

          <button
            v-if="!isPlaying"
            class="aspect-video w-full lg:px-0 cursor-pointer flex items-center justify-center relative"
            :aria-label="$texts('video.load', 'Video laden')"
            @click.prevent="isPlaying = true"
          >
            <RokkaImage
              v-if="video?.thumbnailCustom"
              :config="imageStyle"
              loading="lazy"
              :source-width="video?.thumbnailCustom?.file?.rokka?.sourceWidth"
              :source-height="video?.thumbnailCustom?.file?.rokka?.sourceHeight"
              v-bind="video.thumbnailCustom"
              :hash="video?.thumbnailCustom?.file?.rokka?.hash || ''"
              class="absolute top-0 left-0 w-full"
            />

            <img
              v-else-if="thumbnailUrl"
              :src="thumbnailUrl"
              class="absolute top-0 left-0 size-full object-cover"
              :alt="videoDescription"
            />

            <img
              v-else
              :src="video?.thumbnailOriginal?.entity?.uri?.first?.url"
              class="absolute top-0 left-0 size-full object-cover"
              :alt="videoDescription"
            />

            <SpriteSymbol name="play" class="size-[75px] z-10" />
          </button>

          <figcaption
            v-if="videoDescription || source"
            class="text-sm md:flex md:flex-wrap md:gap-1 justify-between mt-2 max-w-[728px] mx-auto"
          >
            <div v-if="videoDescription">
              {{ videoDescription }}
            </div>
            <div v-if="source" class="text-gray-900/60 mt-2 md:mt-0">
              &copy; {{ source }}
            </div>
          </figcaption>
        </figure>
      </VuepalRemoteVideo>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphVideoFragment } from '#graphql-operations'
import { RokkaImage } from '#components'

const { options } = defineBlokkli({
  bundle: 'video',
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
  },
  globalOptions: ['spacing'],
  options: {
    format: {
      type: 'radios',
      label: 'Bildformat',
      default: 'full',
      displayAs: 'icons',
      options: {
        full: { label: 'extra large', icon: 'icon-blokkli-option-xlarge' },
        big: { label: 'large', icon: 'icon-blokkli-option-large' },
        text: { label: 'medium', icon: 'icon-blokkli-option-medium' },
        small: { label: 'small', icon: 'icon-blokkli-option-small' },
      },
    },
  },
})

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }
  return classList
})

const bigGrid = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 770,
    sm: 984,
    md: 1380,
    lg: 1380,
  },
})

const textGrid = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 728,
    sm: 1000,
    md: 912,
    lg: 912,
  },
})

const smallGrid = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 400,
    sm: 767,
  },
})

const fullWidth = defineImageStyle({
  type: 'sizes',
  aspectRatio: 16 / 9,
  sizes: {
    xs: 728,
    sm: 984,
    md: 1400,
    lg: 1920,
  },
})

const props = defineProps<{
  video?: ParagraphVideoFragment['video']
  videoDescription?: ParagraphVideoFragment['videoDescription']
  source?: ParagraphVideoFragment['source']
}>()

const { $texts } = useEasyTexts()

/**
 * The responsive image style name to use.
 */
const imageStyle = computed(() => {
  if (options.value.format === 'big') {
    return bigGrid
  } else if (options.value.format === 'text') {
    return textGrid
  } else if (options.value.format === 'small') {
    return smallGrid
  }
  return fullWidth
})

const isPlaying = ref(false)

const videoUrl = computed(() => props.video?.url)

const layoutClasses = computed(() => {
  if (options.value.format === 'big') {
    return 'col-span-4 sm:col-span-6 md:col-span-8 lg:col-span-12'
  } else if (options.value.format === 'text') {
    return 'col-span-4 sm:col-span-6 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-8'
  } else if (options.value.format === 'small') {
    return 'col-span-4 md:col-start-2 md:col-span-5 lg:col-start-3 lg:col-span-6'
  }
  return 'w-full flex flex-col justify-center items-center'
})

const gridContainerSize = computed(() => {
  if (options.value.format !== 'full') {
    return 'grid-container'
  }
  return ''
})
</script>
