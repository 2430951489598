<template>
  <section
    class="cta-inner"
    :class="gridClassList"
    :data-image-align="imageAlign"
  >
    <div
      class="cta-content"
      :class="
        imageAlign === 'right' ? contentContainerClasses : imageContainerClasses
      "
    >
      <slot :name="imageAlign === 'right' ? 'content' : 'image'" />
    </div>

    <div
      class="cta-content"
      :class="
        imageAlign === 'right' ? imageContainerClasses : contentContainerClasses
      "
    >
      <slot :name="imageAlign === 'right' ? 'image' : 'content'" />
    </div>
  </section>
</template>

<script lang="ts" setup>
const props = defineProps<{
  isBreakout?: boolean
  imageAlignment: 'left' | 'right'
}>()

const imageAlign = computed(() => {
  return props.imageAlignment === 'right' ? 'right' : 'left'
})

const gridClassList = computed(() => {
  const gridClassList = ['grid-container']

  if (props.isBreakout) {
    gridClassList.push('breakout-' + imageAlign.value)
  }

  return gridClassList
})

const imageContainerClasses = computed(() => {
  return 'col-span-4 sm:col-span-6 md:col-span-4 lg:col-span-7'
})

const contentContainerClasses = computed(() => {
  return 'col-span-4 sm:col-span-6 md:col-span-4 lg:col-span-5 self-center order-2 md:order-none'
})
</script>

<style lang="postcss">
.cta-content:empty {
  @apply hidden;
}
</style>
