<template>
  <div class="container" :class="paragraphClassList" :open="isEditing">
    <h2
      v-blokkli-editable:field_title
      class="text-3xl md:text-4xl mb-10 lg:mb-14"
    >
      {{ title }}
    </h2>
    <div class="grid-container">
      <div
        class="paragraph-icon-text-list col-span-4 sm:col-span-6 md:col-span-8 lg:col-span-12"
      >
        <div
          v-blokkli-editable:field_text="{ type: 'frame' }"
          class="ck-content hyphens-auto"
          v-html="text"
        />
        <BlokkliField
          :list="paragraphs"
          name="field_content"
          class="grid-container py-16 md:pb-5"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphIconTextListFragment } from '#graphql-operations'

defineProps<{
  text: ParagraphIconTextListFragment['text']
  title: ParagraphIconTextListFragment['title']
  paragraphs: ParagraphIconTextListFragment['paragraphs']
}>()

const { isEditing, options } = defineBlokkli({
  bundle: 'icon_text_list',
  editor: {
    editTitle: (el) => el.querySelector('h2')?.textContent,
    getDraggableElement: (el) => el.querySelector('.paragraph-icon-text-list'),
  },
  globalOptions: ['spacing'],
})

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  return classList
})
</script>
