<template>
  <div
    class="structured-table"
    :class="{
      'has-mobile-style': hasMobileStyle,
      'is-sticky-left': !hasMobileStyle && sticky === 'left' && rowCount > 2,
      'is-sticky-top': !hasMobileStyle && sticky === 'top',
      'has-equalized-widths': equalizeWidths,
    }"
  >
    <table>
      <thead v-if="thead.length">
        <tr v-for="(row, i) in thead" :key="'thead_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'thead_' + i + '_' + j"
            v-bind="cell.attributes"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </thead>

      <tbody v-if="tbody.length">
        <tr v-for="(row, i) in tbody" :key="'tbody_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'tbody_' + i + '_' + j"
            v-bind="getCellAttributes(cell, j)"
          >
            <div class="ck-content" v-html="cell.content" />
          </component>
        </tr>
      </tbody>

      <tfoot v-if="tfoot.length">
        <tr v-for="(row, i) in tbody" :key="'tfoot_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'tfoot_' + i + '_' + j"
            v-bind="cell.attributes"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script lang="ts" setup>
import type { StructuredTableCellFragment } from '#graphql-operations'
import { decodeEntities } from '~/helpers/string'

const props = defineProps<{
  mobileStyle: 'horizontal' | 'vertical'
  sticky: 'none' | 'left' | 'top'
  equalizeWidths?: boolean
  thead: StructuredTableCellFragment[][]
  tbody: StructuredTableCellFragment[][]
  tfoot: StructuredTableCellFragment[][]
  id?: string

  // This is not needed in this component, but it's part of the fragment.
  // We define it here so that v-bind can still be used when rendering this
  // component with GraphQL data.
  caption?: string
}>()

const hasMobileStyle = computed(() => props.mobileStyle === 'vertical')

function getCellAttributes(cell: StructuredTableCellFragment, index: number) {
  const attributes: Record<string, string> = cell.attributes || {}
  if (
    props.thead.length &&
    props.thead[0] !== undefined &&
    props.thead[0][index] !== undefined
  ) {
    const label = (props.thead[0][index].content || '').trim()
    attributes['data-head-label'] = decodeEntities(
      label.replace(/<[^>]*>?/gm, '').replace(/\{(.*?)\}/, ' ($1)'),
    )
  }
  return attributes
}

const rowCount = computed(() => props.tbody[0]?.length)

defineOptions({
  name: 'StructuredTable',
})
</script>

<style lang="postcss">
.structured-table {
  @screen md {
    .text-align-right {
      @apply text-right;
    }
  }
  table {
    @apply w-full text-base lg:text-lg;
  }
  td {
    /* @apply tracking-none; */
    @screen md {
      @apply min-w-[150px];
    }
  }

  th,
  td {
    @apply p-0;
    > div {
      @apply p-2;
    }
    @apply text-left align-top;
    @screen md {
      @apply text-balance;
    }
  }
  thead {
    @apply text-body;
    th {
      @apply align-bottom;
    }
  }

  @apply mb-5;

  tbody {
    tr:first-child {
      td,
      th {
        @apply border-t border-t-gray-300;
      }
    }
    td,
    th {
      @apply border-b border-b-gray-300;
      > div {
        @apply p-2;
      }
    }
  }
  td,
  th {
    > div {
      @apply first:pl-0;
    }
  }

  caption {
    @apply text-left font-bold mb-10 text-xl;
  }

  a {
    @apply underline transition-opacity decoration-gray-300 underline-offset-4 hover:decoration-gray-800;
  }

  &.has-mobile-style {
    thead {
      @apply mobile-only:hidden;
    }
    tbody th,
    tbody tr,
    tbody td {
      @apply mobile-only:block;
    }

    td[data-head-label]:before {
      @apply mobile-only:content-[attr(data-head-label)] mobile-only:block mobile-only:text-xs mobile-only:font-medium mobile-only:mb-5;
    }

    td {
      @apply mobile-only:px-0;
      p {
        @apply not-last:mb-5;
      }
    }
  }

  &.is-sticky-left {
    @media screen and (max-width: theme('screens.md')) {
      @apply overflow-auto pb-10;

      th:first-child {
        @apply sticky left-0 bg-white pr-0 whitespace-normal;
        > div {
          width: 100vw;
          max-width: 30vw;
        }

        + td > div,
        + th > div {
          @apply pl-20;
        }
      }
    }
  }

  &.is-sticky-top {
    thead th {
      @apply sticky top-0 bg-white;
    }
  }

  &.has-equalized-widths {
    table {
      table-layout: fixed;
    }

    @screen md {
      td,
      th {
        &:not(:first-child) {
          @apply pl-12;
        }
      }
    }
  }
}
</style>
