<template>
  <div class="container" :class="paragraphClassList">
    <hr class="my-8 bg-gray-400" />
  </div>
</template>

<script lang="ts" setup>
const { options } = defineBlokkli({
  bundle: 'divider',
  globalOptions: ['spacing'],
})

const paragraphClassList = computed(() => {
  const classList = []

  if (options.value.spacing === 'small') {
    classList.push('py-6', 'lg:py-10')
  } else if (options.value.spacing === 'large') {
    classList.push('py-12', 'lg:py-20')
  }

  return classList
})
</script>
