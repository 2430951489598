<template>
  <div class="md:mb-12 lg:mb-20">
    <Container>
      <h2 class="col-span-12 text-3xl md:text-4xl mb-10 lg:mb-14">
        {{ title }}
      </h2>
    </Container>
    <ClientOnly>
      <SVTeaserCarousel
        v-if="showCarousel === true"
        :is-mock="isEditing"
        :items-to-show-desktop="3.5"
        class="container mx-auto teaser-carousel"
      >
        <BlokkliField
          v-if="isEditing"
          name="field_paragraphs_teaser"
          :list="paragraphs"
          class="paragraph-teaser-list grid-container mb-12"
        />

        <Slide v-for="(slide, i) in paragraphs" v-else :key="i" class="px-3">
          <BlokkliItem v-bind="slide" no-link-wrap />
        </Slide>
      </SVTeaserCarousel>
    </ClientOnly>
    <BlokkliField
      v-if="showCarousel === false"
      :list="paragraphs"
      class="paragraph-teaser-list grid-container mb-12"
      :class="teaserStyleClasses"
      name="field_paragraphs_teaser"
    />
  </div>
</template>

<script lang="ts" setup>
import { Slide } from 'vue3-carousel'
import type { ParagraphTeaserListFragment } from '#graphql-operations'

defineProps<{
  title?: string
  paragraphs?: ParagraphTeaserListFragment['paragraphs']
}>()

const { options, isEditing } = defineBlokkli({
  bundle: 'teaser_list',
  options: {
    teaserStyle: {
      type: 'radios',
      label: 'Darstellung',
      default: 'grid',
      displayAs: 'icons',
      options: {
        grid: { label: 'grid', icon: 'icon-blokkli-option-grid' },
        staggered: {
          label: 'Versetzt',
          icon: 'icon-blokkli-option-staggered',
        },
        sliderDesktop: {
          label: 'Slider desktop',
          icon: 'icon-blokkli-option-slider-desktop',
        },
      },
    },
    sliderStyle: {
      type: 'radios',
      label: 'Slider Darstellung',
      default: 'stack',
      displayAs: 'icons',
      options: {
        stack: { label: 'Stapel', icon: 'icon-blokkli-option-stapel' },
        sliderMobile: {
          label: 'Slider mobile',
          icon: 'icon-blokkli-option-slider',
        },
      },
    },
  },
  editor: {
    editTitle: (el) => el.querySelector('h2')?.innerText,
    addBehaviour: 'no-form',
  },
})

const viewport = useViewport()
const showCarousel = computed(() => {
  return (
    ((viewport.breakpoint.value === 'xs' ||
      viewport.breakpoint.value === 'sm') &&
      options.value.sliderStyle === 'sliderMobile') ||
    options.value.teaserStyle === 'sliderDesktop'
  )
})

const teaserStyle = computed(() => options.value.teaserStyle)

const teaserStyleClasses = computed(() => {
  if (options.value.teaserStyle === 'staggered') {
    return ['staggered', 'items-start']
  }

  return ['items-stretch']
})

provide('paragraphTeaserStyle', teaserStyle)
</script>

<style lang="postcss">
.paragraph-teaser-list.staggered {
  .paragraph-teaser:not(:nth-child(3n + 2)) {
    @apply lg:mt-28;
  }
}
</style>
