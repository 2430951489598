<template>
  <VuepalRemoteVideo
    v-if="videoUrl"
    v-slot="{ embedUrl, thumbnailUrl }"
    :url="videoUrl"
  >
    <figure class="w-full max-w-[1920px]">
      <iframe
        v-if="embedUrl && isPlaying"
        :src="embedUrl"
        allow="autoplay; encrypted-media; picture-in-picture"
        allowfullscreen
        class="aspect-video w-full lg:px-0"
      />

      <button
        v-if="!isPlaying"
        class="aspect-video w-full lg:px-0 cursor-pointer flex items-center justify-center relative"
        :aria-label="$texts('video.load', 'Video laden')"
        @click.prevent="isPlaying = true"
      >
        <RokkaImage
          v-if="video?.thumbnailCustom"
          :config="usedImageStyle"
          loading="lazy"
          :source-width="video?.thumbnailCustom?.file?.rokka?.sourceWidth"
          :source-height="video?.thumbnailCustom?.file?.rokka?.sourceHeight"
          v-bind="video.thumbnailCustom"
          :hash="video?.thumbnailCustom?.file?.rokka?.hash || ''"
          img-class="size-full"
          class="absolute top-0 left-0 h-full"
        />

        <img
          v-else-if="thumbnailUrl"
          :src="thumbnailUrl"
          class="absolute top-0 left-0 size-full object-cover"
          alt="Video"
        />

        <img
          v-else
          :src="video?.thumbnailOriginal?.entity?.uri?.first?.url"
          class="absolute top-0 left-0 size-full object-cover"
          alt="Video"
        />

        <SpriteSymbol name="play" class="size-[75px] z-10" />
      </button>
    </figure>
  </VuepalRemoteVideo>
</template>

<script lang="ts" setup>
import type { VideoFragment } from '#graphql-operations'
import type {
  DefineImageStyleConfigSingle,
  DefineImageStyleConfigSizes,
  DefineImageStyleConfigPictures,
} from '#rokka/types'

const props = defineProps<{
  imageStyle:
    | DefineImageStyleConfigSingle
    | DefineImageStyleConfigSizes
    | DefineImageStyleConfigPictures
  video: VideoFragment
  videoUrl?: string
  isBreakout?: boolean
}>()

const isPlaying = ref(false)

const { $texts } = useEasyTexts()

const usedImageStyle = computed(() => {
  return props.isBreakout ? breakoutImageStyleConfig : standardImageStyleConfig
})

const standardImageStyleConfig = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 768,
      aspectRatio: 16 / 9,
    },
    sm: {
      width: 1024,
      aspectRatio: 16 / 9,
    },
    md: {
      width: 1280,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: 690,
      aspectRatio: 16 / 9,
    },
    xl: {
      width: 640,
      aspectRatio: 16 / 9,
    },
  },
})

const breakoutImageStyleConfig = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 768,
      aspectRatio: 16 / 9,
    },
    sm: {
      width: 1024,
      aspectRatio: 16 / 9,
    },
    md: {
      width: 1280,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: 1024,
      aspectRatio: 16 / 9,
    },
    xl: {
      width: 1280,
      aspectRatio: 16 / 9,
    },
  },
})
</script>
