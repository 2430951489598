export function decodeEntities(encodedString: string) {
  const RGX = /&(nbsp|amp|quot|lt|gt);/g
  const translate: Record<string, string> = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  }
  return encodedString
    .replace(RGX, function (_match, entity) {
      return translate[entity]
    })
    .replace(/&#(\d+);/gi, function (_match, numStr) {
      const num = parseInt(numStr, 10)
      return String.fromCharCode(num)
    })
}
