<template>
  <div
    class="col-span-4 sm:col-span-6 md:col-span-4 lg:col-span-6 flex flex-col gap-4 items-center sm:items-start sm:flex-row pb-8 sm:py-8 border-b"
  >
    <div v-blokkli-droppable:field_icon class="sm:w-[132px] sm:shrink-0">
      <img
        :src="icon?.mediaFileUrl?.path"
        class="size-[100px] sm:size-[50px] sm:mx-10"
        :class="text && 'sm:mt-10'"
      />
    </div>
    <div class="grid">
      <h3 v-blokkli-editable:field_title class="text-3xl col-span-7 mb-2">
        {{ title }}
      </h3>
      <div
        v-blokkli-editable:field_description="{ type: 'frame' }"
        class="ck-content hyphens-auto col-span-7"
        v-html="text"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphIconTextItemFragment } from '#graphql-operations'

defineProps<{
  icon: ParagraphIconTextItemFragment['icon']
  text: ParagraphIconTextItemFragment['text']
  title: ParagraphIconTextItemFragment['title']
}>()

defineBlokkli({
  bundle: 'icon_text_item',
})
</script>
