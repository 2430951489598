<template>
  <div v-if="isMock">
    <slot />
  </div>

  <Carousel
    v-else
    ref="carouselInstance"
    :items-to-show="itemsToShowMobile || 1.3"
    :breakpoints="breakPoints"
    :wrap-around="false"
    :transition="750"
    @click.capture="onClick"
  >
    <slot />

    <template #addons>
      <div
        ref="navigation"
        class="carousel__navigation-container--outer bottom-[-88px]"
      >
        <div class="carousel__navigation-container">
          <nav class="carousel__navigation-container--inner">
            <button
              class="carousel__prev"
              :aria-label="$texts('slides.prev', 'Vorherige')"
              @click="prev()"
            >
              <SpriteSymbol name="arrow-left" class="size-6" />
            </button>

            <button
              class="carousel__next"
              :aria-label="$texts('slides.next', 'Nächste')"
              @click="next()"
            >
              <SpriteSymbol name="arrow-right" class="size-6" />
            </button>
          </nav>
        </div>
      </div>
    </template>
  </Carousel>
</template>

<script lang="ts" setup>
import { Carousel } from 'vue3-carousel'

const navigation = ref<HTMLElement>()

const props = defineProps<{
  // Used for Blökkli. We don't want editors to have to scroll through the slides in order to add child blocks or edit existing slides.
  isMock?: boolean
  itemsToShowMobile?: number
  itemsToShowDesktop?: number
}>()

const { $texts } = useEasyTexts()

const breakPoints = ref({
  1280: {
    itemsToShow: props.itemsToShowDesktop || 1.5,
  },
})

const carouselInstance = ref<typeof Carousel | null>(null)

function prev() {
  carouselInstance.value?.prev()
}

function next() {
  carouselInstance.value?.next()
}

function onClick(e: MouseEvent) {
  if (!e.target || !(e.target instanceof Element)) {
    return
  }

  const closestLi = e.target.closest('li')

  if (!closestLi) {
    return
  }

  if (closestLi.classList.contains('carousel__slide--prev')) {
    prev()
  }

  if (closestLi.classList.contains('carousel__slide--next')) {
    next()
  }
}
</script>
